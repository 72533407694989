import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Gymnastic Day`}</em></strong></p>
    <p>{`5 sets of as many reps as possible (rest between sets)`}</p>
    <p>{`Strict HSPU’s`}</p>
    <p>{`“Deficit” Ring Rows (rings at hip height, feet elevated on bench)`}</p>
    <p>{`then,`}</p>
    <p>{`40-30-20-10 each:`}</p>
    <p>{`Calorie Ski Erg`}</p>
    <p>{`Pushups`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Situps`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Friday will be our 12 Days of Christmas WOD at 9:00 & 10:30am.
All other classes that day are`}</em></strong>{` `}<strong parentName="p"><em parentName="strong">{`cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed Saturday, Christmas Day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      